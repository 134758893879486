/*
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-29 16:52:53
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-06 18:17:50
 */
import { post, get } from '@/utils/request'
const path = '/train'
const API = {
  // 管理员端-------------------------
  getStudyPlanList: path + '/trStudyPlan/findByPage', // 查询学习计划
  getStatusTotal: path + '/trStudyPlan/statusTotal', // 获取学习计划各个状态总数
  addStudyPlan: path + '/trStudyPlan/add', // 新增学习计划
  studyPlanDetail: path + '/trStudyPlan/detail', // 学习计划详情
  editPlanDetail: path + '/trStudyPlan/edit', // 学习计划编辑
  updateStatus: path + '/trStudyPlan/updateStatus', // 改变学习计划状态
  removeTrStudyPlan: path + '/trStudyPlan/remove', // 改变学习计划状态

  // 课程安排
  getStudyPlanCourseList: path + '/trStudyPlanCourse/getStudyPlanCourse', // 课程安排
  createOrUpdate: path + '/trStudyPlanCourse/create', // 添加课程安排
  editOrUpdate: path + '/trStudyPlanCourse/edit', // 修改课程安排
  removeOrUpdate: path + '/trStudyPlanCourse/remove', // 删除课程安排
  getCourseDetial: path + '/trStudyPlanCourse/detail', // 获取课程详情
  getRelevanceCourse: path + '/trStudyPlanCourse/examCourse', // 获取关联课程
  editExam: path + '/examInfo/edit', // 编辑考试
  // 学习计划关联阶段
  addTrStudyPlanStage: path + '/trStudyPlanStage/add', // 新增
  editTrStudyPlanStage: path + '/trStudyPlanStage/edit', // 编辑
  // 受训学员
  getTraineeList: path + '/trStudyPlanEmployee/findByPage', // 受训学员
  deleteStudent: path + '/trStudyPlanEmployee/remove', // 移除对应学员
  addStudent: path + '/trStudyPlanEmployee/createOrUpdate', // 添加对应学员
  // 员工端-----------------------------------
  getStudentLearnArrange: path + '/trStudyPlan/employeeStudyPlan', // 学习安排列表查询
  getStudentLearnArrangeDetail: path + '/trStudyPlan/employeePlanBy', // 员工端查询学习安排详情
  getExamInfoByStudyId: path + '/trStudyPlanCourse/employeeExamInfo', // 员工端根据学习计划id获取考试信息
  getArrangeCourseByStudyId: path + '/trStudyPlanCourse/employeeCourse', // 员工端根据学习计划id获取考试信息
  getStudyArrangeCourseDetail: path + '/trStudyPlanCourse/getOnLineDetail', // 学习安排线上课程详情
  getUpdateStudyPace: path + '/scheduleOnlineCourse/updateSchedule', // 更新目录的学习进度
  getUserExamArrangeList: path + '/employeeExam/personnelExamPage', // 员工学习安排列表查询
  getUserOfflineSignIn: path + '/trStudySchedule/updateStatus', // 员工线下签到

  // 学习进度
  getStudyProsessList: path + '/trStudySchedule/findByPage', // 学习进度列表
  exportStudentProsess: path + '/trStudySchedule/export', // 学习进度导出
  studentProsessDetail: path + '/scheduleOnlineCourse/learningDetail', // 学习进度明细
  // 正在学习
  getBeLearning: path + '/employeeLearning/findByPage', // 获取正在学习列表
  addBeLearning: path + '/employeeLearning/add', // 新增学习
  getStudyDetail: path + '/employeeLearning/findById', // 获取正在学习详情
  uploadStudyProgress: path + '/employeeLearning/progress', // 更新正在学习进度
  deleteBeLearningStudy: path + '/employeeLearning/remove'// 正在学习视频删除
}
export const getStudyPlanListApi = (data) => post(API.getStudyPlanList, data)
export const removeTrStudyPlanApi = (data) => post(API.removeTrStudyPlan, data)
export const getStatusTotalApi = (data) => post(API.getStatusTotal, data)
export const addStudyPlanApi = (data) => post(API.addStudyPlan, data)
export const studyPlanDetailApi = (data) => get(API.studyPlanDetail, data)
export const editPlanDetailApi = (data) => post(API.editPlanDetail, data)
export const updateStatusApi = (data) => post(API.updateStatus, data)
// 课程安排
export const getStudyPlanCourseListApi = (data) => get(API.getStudyPlanCourseList, data)
export const createOrUpdateApi = (data) => post(API.createOrUpdate, data)
export const editOrUpdateApi = (data) => post(API.editOrUpdate, data)
export const removeOrUpdateApi = (data) => get(API.removeOrUpdate, data)
export const getCourseDetialApi = (data) => get(API.getCourseDetial, data)
export const getRelevanceCourseApi = (data) => get(API.getRelevanceCourse, data)
export const editExamApi = (data) => post(API.editExam, data)
// 学习计划关联阶段
export const addTrStudyPlanStageApi = (data) => post(API.addTrStudyPlanStage, data)
export const editTrStudyPlanStageApi = (data) => post(API.editTrStudyPlanStage, data)
// 受训学员
export const getTraineeListApi = (data) => post(API.getTraineeList, data)
export const deleteStudentApi = (data) => post(API.deleteStudent, data)
export const addStudentApi = (data) => post(API.addStudent, data)
// 员工端-----------------------------------
export const getStudentLearnArrangeApi = (data) => post(API.getStudentLearnArrange, data)// 学习安排列表查询
export const getStudentLearnArrangeDetailApi = (data) => get(API.getStudentLearnArrangeDetail, data)// 员工端查询学习安排详情
export const getExamInfoByStudyIdApi = (data) => get(API.getExamInfoByStudyId, data)// 员工端根据学习计划id获取考试信息
export const getArrangeCourseByStudyIdApi = (data) => get(API.getArrangeCourseByStudyId, data) // 员工端根据学习计划id获取考试信息
export const getStudyArrangeCourseDetailApi = (data) => get(API.getStudyArrangeCourseDetail, data) // 员工端根据学习计划id获取考试信息
export const getUpdateStudyPaceApi = (data) => post(API.getUpdateStudyPace, data) // 更新目录的学习进度
export const getUserExamArrangeListApi = (data) => get(API.getUserExamArrangeList, data) // 员工学习安排列表查询
export const getUserOfflineSignInApi = (data) => post(API.getUserOfflineSignIn, data) // 员工线下签到

// 学习进度
export const getStudyProsessListApi = (data) => post(API.getStudyProsessList, data)
export const exportStudentProsessApi = (data) => post(API.exportStudentProsess, data, {
  responseType: 'blob'
})
export const studentProsessDetailApi = (data) => post(API.studentProsessDetail, data)
export const getBeLearningApi = (data) => post(API.getBeLearning, data)
export const addBeLearningApi = (data) => post(API.addBeLearning, data)
export const getStudyDetailApi = (data) => get(API.getStudyDetail, data)
export const uploadStudyProgressApi = (data) => post(API.uploadStudyProgress, data)
export const deleteBeLearningStudyApi = (data) => post(API.deleteBeLearningStudy, data)
