<template>
  <div class="container-warp">
    <div class="container-warp-content">
      <div class="empty-box" v-if="!belearningList.length">
        <PlaceholderMap text="暂无学习记录噢~" />
      </div>
      <div class="set-box" v-else>
        <div
          class="course-item"
          v-for="(item, index) in belearningList"
          :key="index"
          @click.stop="godetail(item)"
        >
          <div class="img-box">
            <img v-if="!item.cover" src="@/assets/img/Course.png" alt="">
            <img v-else :src="item.cover" alt="" />
            <div class="pitch-box">共{{ item.sectionNum }}节</div>
            <div class="state-box" v-if="item.courseStatus == 0">已下架</div>
          </div>
          <div class="bottom-box">
            <div class="name-box">

              <div>{{ item.courseName }}</div>

            </div>
            <div class="progress-box">
              <div style="margin-right: 10px">
                已学{{ item.learningRatio || 0 }}%
              </div>
              <i
              v-if="item.courseStatus == 0"
                @click.stop="_deleteTrainee(item, index)"
                style="color: #f04134; margin-left: 8px"
                class="iconfont icon-icon_delete"
              ></i>
            </div>
            <el-progress
              :percentage="item.learningRatio"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      class="fx-row-end pt16"
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
      :current-page="searchForm.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="searchForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchForm.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getBeLearningApi, deleteBeLearningStudyApi } from '@/api/study'
import PlaceholderMap from '@/components/placeholderMap/index.vue'

export default {
  components: {
    PlaceholderMap
  },
  data () {
    return {
      searchForm: {
        page: 1,
        pageSize: 10,
        total: 1
      },
      belearningList: []
    }
  },
  created () {
    this.getBeLearning()
  },
  methods: {
    getBeLearning () {
      getBeLearningApi(this.searchForm).then((res) => {
        if (res.code === 200) {
          this.belearningList = res.object.results
          this.searchForm.total = res.object.total
        }
      })
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.getBeLearning()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.getBeLearning()
    },
    godetail (item) {
      if (item.courseStatus == 0) {
        this.$message.info('联系课程相关负责人！')
        return
      }
      this.$router.push({
        path: `/onlineCourseDetail?courseId=${item.courseId}&learningCourseId=${item.learningCourseId}&pageValue=studying`
      })
    },
    // 删除课程资料吗，=
    _deleteTrainee (item, index) {
      // 删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteBeLearningStudyApi([item.learningCourseId]).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.getBeLearning()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-warp {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
}
.container-warp-content {
  height: calc(100vh - 260px);
  overflow: auto;
}
.course-item {
  margin: 0 24px 24px 0;
  width: 208px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px rgba(17, 44, 69, 0.02),
    0px 8px 28px 0px rgba(37, 73, 114, 0.05);
  border-radius: 4px 4px 4px 4px;
  .img-box {
    position: relative;
    width: 100%;
    height: 156px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .pitch-box {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3px 12px;
      font-size: 12px;
      color: #0089ff;
      background-color: #ebf6ff;
      border-radius: 4px;
    }
    .state-box {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 3px 12px;
      font-size: 12px;
      color: #4d4d4d;
      background-color: #d9d9d9;
      border-radius: 4px;
    }
  }
  .bottom-box {
    padding: 8px 12px 10px;
    .name-box {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      img{
        width: 24px;
        height: 25px;
        margin-right: 4px;
      }
      div {
        &:first-child {
          margin-right: 16px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          max-width:200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .progress-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #666666;
      margin-bottom: 10px;
      /deep/.el-progress {
        width: 118px;
      }
    }
  }
}
.set-box {
  display: flex;
  flex-wrap: wrap;
}
.empty-box {
  height: calc(100vh - 260px);
  overflow: hidden;
}
</style>
