<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-17 13:26:43
 * @LastEditors: wenwen
 * @LastEditTime: 2024-02-06 11:02:31
-->
<template>
  <div class="null-img">
    <div class="img-content">
        <img src="@/assets/img/img_null_data.png" alt="">
        <div class="text">{{text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.null-img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    img{
        width: 310px;
        height: 310px;
    }
    .text{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
}
</style>
